import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Button, Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$s, logger} from '@bitstillery/common/app'
import {match} from 'path-to-regexp'
import {next_tick} from '@bitstillery/common/lib/proxy'

export interface TabsAttrs {
    /** An array of arbitrary <Tab> components */
    children: any[]
    /** Optional class name */
    className?: string
    /** Optional data object to sync the active tab (legacy; use route instead) */
    data?: any
    /** This is used together with the nested view; e.g. tabs are styled as a sort of breadcrumb trail*/
    header?: {
        icon?: string
        onclick?: () => void
        title?: string
    }
    route: string | string[]
    tab_id?: string
    type?: 'default' | 'nested-view'
}

export class Tabs extends MithrilTsxComponent<TabsAttrs> {

    tab_id: string

    oninit(vnode:m.Vnode<any>) {
        this.data = vnode.attrs.data

        if (vnode.attrs.data) {
            this.tab_id = vnode.attrs.tab_id ? vnode.attrs.tab_id : 'tabId'
        }

        for (const tab of vnode.children) {
            if (!tab) continue
            if (this.is_tab_active(tab)) {
                this.activate_tab(tab, true)
            }
        }

        // This is a special kind of view that adds an extra
        // submenu layer. See the .extra-submenu class at .l-main-col
        if (vnode.attrs.type === 'nested-view') {
            $s.page.header.submenu = true
        }
    }

    onremove(vnode) {
        if (vnode.attrs.type === 'nested-view') {
            $s.page.header.submenu = false
        }
    }

    async activate_tab(tab, on_init = false) {
        if (on_init) {
            if (tab.attrs.onactivate) {
                await next_tick()
                tab.attrs.onactivate(tab, on_init)
            }
            return
        }

        const current_path = m.parsePathname(m.route.get())
        const current_params = Object.fromEntries(new URLSearchParams(current_path.params))
        if (tab.attrs.link && tab.attrs.route) {
            const link_path = m.parsePathname(tab.attrs.link)

            const match_route = match(tab.attrs.route)
            if (match_route(link_path.path)) {
                m.route.set(link_path.path, current_params, {replace: true})
            }
        } else {
            if (current_params.tabId !== tab.attrs.id) {
                const new_params = {...current_params, [this.tab_id]: tab.attrs.id}
                logger.debug(`[tabs] update tab id (${current_params.tabId} => ${this.tab_id})`)
                m.route.set(current_path.path, new_params, {replace: true})
                this.data.tabs.active = tab.attrs.id
            }
        }

        if (tab.attrs.onactivate) {
            await next_tick()
            tab.attrs.onactivate(tab, on_init)
        }
    }

    is_tab_active(tab: m.Vnode<TabsAttrs>) {
        if (tab.attrs.route) {
            if (Array.isArray(tab.attrs.route)) {
                return tab.attrs.route.some(route => {
                    const match_route = match(route)
                    return match_route($s.env.uri)
                })
            }
            const match_route = match(tab.attrs.route)
            return match_route($s.env.uri)
        } else {
            // Legacy mode using tabId
            return tab.attrs.id === this.data.tabs.active
        }
    }

    view(vnode: m.Vnode<TabsAttrs>) {
        return (
            <div className={classes('c-tabs', vnode.attrs.className,
                `type-${vnode.attrs.type ? vnode.attrs.type : 'default'}`,
            )}>
                <div className="tabs-container">
                    {vnode.attrs.header && <div class="header" onclick={() => {
                        if (vnode.attrs.header.onclick) {
                            vnode.attrs.header.onclick()
                        }
                    }}>
                        <Icon name={vnode.attrs.header.icon} type="unset" />
                        <span>{vnode.attrs.header.title}</span>
                    </div>}
                    <div class="tabs">
                        {vnode.children.map((tab) => {
                            if (!tab) return null
                            tab.attrs.data = vnode.attrs.data
                            tab.attrs.activate = this.activate_tab.bind(this, tab, false)
                            tab.attrs.is_tab_active = this.is_tab_active.bind(this, tab)
                            tab.tabs = this
                            return tab
                        })}
                    </div>
                </div>
                <div class="panels">
                    {vnode.children.filter((i) => i).map((tab) => {
                        if (!tab) return null
                        tab.children[0].attrs.id = tab.attrs.id
                        tab.children[0].attrs.data = this.data
                        tab.children[0].attrs.route = vnode.attrs.route
                        tab.children[0].is_tab_active = this.is_tab_active.bind(this, tab)
                        tab.children[0].tabs = this
                        tab.children[0].tab = tab
                        return tab.children[0]
                    })}
                </div>
            </div>
        )
    }
}

interface TabAttrs {
    className?: string
    disabled?: boolean
    icon: string
    is_tab_active: () => boolean
    /** Function from Tabs call when the tab is clicked */
    activate: Function
    /** Function from Tabs component to check if the tab is active */
    route: string | string[]
    tip?: Function | string
    text: string
}

export class Tab extends MithrilTsxComponent<TabAttrs> {

    view(vnode) {
        return <Button
            className={classes(
                'c-tab',
                vnode.attrs.className, {
                    active: vnode.tabs.is_tab_active(vnode),
                })}
            disabled={vnode.attrs.disabled}
            icon={vnode.attrs.icon}
            onclick={vnode.attrs.activate}
            text={vnode.attrs.text}
            tabindex={vnode.attrs.tabindex}
            tip={vnode.attrs.tip}
            type="unset"
            variant="unset"
        />
    }
}

export class TabPanel extends MithrilTsxComponent<any> {

    initialized = false

    view(vnode) {
        return (
            <div className={classes('c-tab-panel', vnode.attrs.className, {
                active: vnode.tabs.is_tab_active(vnode.tab),
            })}>
                {(() => {
                    if (!this.initialized) {
                        // Initialize the tab content on the first time it is activated.

                        if (!vnode.is_tab_active(vnode.tab)) return null
                        this.initialized = true
                    }
                    return vnode.children
                })()}
            </div>
        )
    }
}
