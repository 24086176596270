import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Icon} from '@bitstillery/common/components'
import {$s} from '@bitstillery/common/app'

interface PanelContextAttrs {
    className?: string
    collection?: any
    /** A ContextProvider */
    context?: any
    count?: number
    icon?: string
    keep_open?: boolean
    minimizable?: boolean
    title?: Function | string
}

export class PanelContext extends MithrilTsxComponent<PanelContextAttrs> {

    onremove(vnode) {
        if (!vnode.attrs.keep_open) {
            $s.panels.context.collapsed = true
        }
    }

    view(vnode:m.Vnode<PanelContextAttrs>):m.Children {
        return <div
            className={classes('c-panel-context', vnode.attrs.className, 'float-panel', {
                collapsed: $s.panels.context.collapsed,
                'float-panel--active': $s.env.layout === 'mobile',
                minimizable: vnode.attrs.minimizable,
            })}
            onkeydown={(e) => {
                // Only navigate context when ctrl is pressed.
                if (!e.ctrlKey) return
                if (e.key === 'ArrowUp') {
                    vnode.attrs.context.select_previous(vnode.attrs.collection)
                } else if (e.key === 'ArrowDown') {
                    vnode.attrs.context.select_next(vnode.attrs.collection)
                }
            }}
            tabindex={10}
        >
            <header>
                <div className="title">
                    <Icon
                        name={vnode.attrs.icon ? vnode.attrs.icon : 'card_account_details_outline'}
                        type="info"
                    />
                    <span>{(() => {
                        if (vnode.attrs.context && vnode.attrs.context.data.title) {
                            return vnode.attrs.context.data.title
                        }
                        if (vnode.attrs.title) {
                            const title = typeof vnode.attrs.title === 'function' ? vnode.attrs.title() : vnode.attrs.title
                            return title
                        }
                        return 'Context Panel'
                    })()}</span>
                </div>
                <div className="panel-actions">
                    {(
                        vnode.attrs.context &&
                        vnode.attrs.collection &&
                        // (!) only available in edit-mode
                        vnode.attrs.context.data.entity_artkey
                    ) && [
                        <Button
                            icon="arrowUpCircle"
                            onclick={() => vnode.attrs.context.select_previous(vnode.attrs.collection)}
                            tip="Previous item in the collection (ctrl + ↑)"
                            type="info"
                            variant="toggle"
                        />,
                        <Button
                            icon="arrowDownCircle"
                            onclick={() => vnode.attrs.context.select_next(vnode.attrs.collection)}
                            tip="Next item in the collection (ctrl + ↓)"
                            type="info"
                            variant="toggle"
                        />,
                    ]}

                    {!vnode.attrs.minimizable && <Button
                        icon="close"
                        tip="Close this panel"
                        onclick={() => {
                            if (vnode.attrs.context) {
                                vnode.attrs.context.select_none(vnode.attrs.collection)
                            } else {
                                $s.panels.context.collapsed = true
                            }
                        }}
                        variant="toggle"
                    />}
                </div>
            </header>
            {vnode.children}
        </div>
    }
}
